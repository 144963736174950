<script setup lang="ts">
import type { IconClass } from '../../types'
import UiBox from '../container/UiBox.vue'

/**
 * UiNoData
 *
 * The "no data"" component is designed to handle states for missing data, for
 * tables, cards, filters, etc. It aims to provide sensible defaults and simple
 * props to do the heavy lifting.
 *
 * @see https://github.com/forged-com/forgd/pull/2761
 * @see https://github.com/forged-com/forgd/pull/2781
 */
const props = withDefaults(defineProps<{
  icon?: IconClass
  title?: string
  message?: string
  support?: boolean
  action?: keyof typeof actions
  variant?: 'neutral' | 'white'
}>(), {
  title: 'No data',
  message: 'There is no data to display',
  icon: 'i-heroicons-folder-open',
})

const emits = defineEmits(['action'])

defineSlots<{
  default: () => void
  action: () => void
  footer: () => void
}>()

const containerAttrs = computed(() => {
  return props.variant
    ? { is: UiBox, variant: props.variant, size: 'lg', layout: 'divide' }
    : { is: 'div', class: 'w-full h-full' }
})

const actions = {
  refresh: {
    icon: 'i-heroicons-arrow-path',
    label: 'Refresh',
    trailing: true,
  },
  reset: {
    icon: 'i-heroicons-arrow-uturn-left',
    label: 'Reset',
    trailing: true,
  },
  clear: {
    icon: 'i-heroicons-x-mark',
    label: 'Clear',
    trailing: true,
  },
  back: {
    icon: 'i-heroicons-arrow-left',
    label: 'Back',
  },
} satisfies Record<string, { icon: IconClass, label: string, trailing?: boolean }>

const actionAttrs = computed(() => {
  return actions[props.action] || null
})
</script>

<template>
  <component :is="containerAttrs.is" v-bind="containerAttrs">
    <div class="flex flex-grow items-center justify-center min-h-60">
      <div class="text-center leading-none">
        <UiIcon size="xl" :name="icon" color="muted" />

        <!-- title -->
        <div class="font-bold text-primary-900 text-base mt-3">
          {{ title }}
        </div>

        <!-- message -->
        <div v-if="$slots.default || message" class="text-sm text-text-body">
          <slot>
            <span v-html="message.trim().replaceAll(/[\n\r]+/g, '<br>')" />
          </slot>
        </div>

        <!-- action -->
        <div v-if="$slots.action || actionAttrs" class="mt-3">
          <slot name="action">
            <UiButton
              size="sm"
              v-bind="actionAttrs"
              @click="emits('action', props.action)"
            />
          </slot>
        </div>
      </div>
    </div>

    <!-- error -->
    <div v-if="$slots.footer || support" class="text-sm text-center grow-0">
      <slot name="footer">
        If you suspect there is an error,
        <NuxtLink to="/support" class="text-forgd-accent-300 text-semi-bold hover:underline">
          please contact us for support
        </NuxtLink>
        .
      </slot>
    </div>
  </component>
</template>
